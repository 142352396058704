import logo from './logo.svg';
import './App.css';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import InputValue from "./InputValue";
import {useState} from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import PieChart from "./PieChart";
ChartJS.register(ArcElement, Tooltip, Legend);

const TAXABLE_AMT = "Taxable Amount";
const TAX_DEFERRED_AMT = "Tax Deferred Amount";
const TAX_FREE_AMT = "Tax Free Amount";

function App() {
    const [taxableAmt, setTaxableAmt] = useState(0);
    const [taxDeferredAmt, setTaxDeferredAmt] = useState(0);
    const [taxFreeAmt, setTaxFreeAmt] = useState(0);

    let totalAmt = Number(taxableAmt) + Number(taxDeferredAmt) + Number(taxFreeAmt);

    const amountList = [
        { name: TAXABLE_AMT, value: taxableAmt },
        { name: TAX_DEFERRED_AMT, value: taxDeferredAmt },
        { name: TAX_FREE_AMT, value: taxFreeAmt }
    ];

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>

                <InputValue inputName={TAXABLE_AMT}
                            setValue={setTaxableAmt}
                ></InputValue>
                <InputValue inputName={TAX_DEFERRED_AMT}
                            setValue={setTaxDeferredAmt}
                ></InputValue>
                <InputValue inputName={TAX_FREE_AMT}
                            setValue={setTaxFreeAmt}>
                </InputValue>
                <h1>Total Amount: {totalAmt}</h1>
                <PieChart data={amountList} />;
                <Badge bg="secondary" as={Button} name={"Submit"}>
                    Submit
                </Badge>
            </header>
        </div>
    );
}

export default App;
